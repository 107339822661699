import React, { createRef } from 'react';
import Layout from '../components/layout/layout';
import Navigation from '../components/navigation/navigation';
import About from '../components/about/about';
import Footer from '../components/footer/footer';

const TainaEnPage = () => {
  const {taina} = createRef;
  const engArray = [
      {
        key: 1,
        content: `Hello and welcome`,
      },
      {
        key: 2,
        content: `My name is Taina and I come from
        East Helsinki.`,
      },
      {
        key: 3,
        content: `In addition to children, my family
        includes a cat and 3 dogs that take
        up my time. In addition, my hobby&#39;s
        are crafts and competitive dancing
        that make the time pass.`,
      },
      {
        key: 4,
        content: `I graduated as a masseuse a few
        years ago from the massage school
        in East Helsinki. Studying was
        interesting and intensive.`,
      },
      {
        key: 5,
        content: `I meet my clients as individuals and I
        plan each client&#39;s treatment
        personally, always taking into
        account the client&#39;s needs and
        wishes. If necessary, I will give my
        recommendations so that the
        treatment has the best possible
        outcome.`,
      },
      {
        key: 6,
        content: `The well-being of clients is close to
        my heart because taking care of
        others has always been &quot;my thing&quot;.
        There is nothing better than seeing
        how I can help another person.`,
      },
      {
        key: 7,
        content: `I speak Finnish as my native
        language and also fluent English.`,
      },
      {
        key: 8,
        content: `I hope to see you soon
        Regards`,
      },
      {
        key: 9,
        content: `- Your masseuse Taina -`,
      }
  ];

  return (
      <Layout>
        <title>Taina</title>
        <div className='site-wrapper'>
          <Navigation
            homeRoute={`/en`}
            langLink={`FI`}
            langTitle={'Suomenkielinen sivu'}
            language={`/taina`}
            home={`Home`}
          />
          <main className="new-page">
            <About 
              sectionTitle={`Taina`} 
              ref={taina}
              aboutTextArray={engArray}
            />
          </main>
          <Footer followMe={`Follow Us`} contactInfo={`Contact`}/>
        </div>
     </Layout>
  )
}

export default TainaEnPage;